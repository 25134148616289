<template>
  <div class="p-root">
    <div class="full-width artists-banner">
      <img :src="icons.artists_banner" class="full-width" />
      <img :src="icons.artists_huxian" class="full-width artists-huxian" />
    </div>

    <div class="full-width column-center" style="padding: 50px 0">
      <div class="artists-content-width column">
        <!-- <span class="artists-title" style="align-self: center">“哈罗~合作者”</span> -->
        <img :src="icons.artists_en_paszko" style="width:400px;align-self:center" />
        <span class="artists-label" style="margin-top: 50px">Malgorzata PASZKO</span>

        <p class="artists-content">
          Malgorzata Paszko est née à Varsovie en 1956.
          En 1975, École des Beaux-Arts de Varsovie, puis École des Beaux-Arts de Paris.
          En 1979, à 23 ans, elle est exposée par la galerie Le dessin dirigée par Claire Burrus à la FIAC.
          De nombreuses expositions ont eu lieu, ensuite ; galerie Camomille et galerie Fred Lanzenberg à Bruxelles,
          galerie Koralewski à Paris. Suivis d’autres lieux en France, Allemagne, Grèce, Pologne et par la galerie
          Mogabgab Beyrouth, au Liban…
          En 1986 -1987, l’artiste a été pensionnaire de la Villa Médicis à Rome. Malgorzata Paszko s’inscrit dans la
          mouvance du Paysage revisité.
          Chaque artiste acquiert ce que l’on peut appeler la peau de sa peinture ou sa propre texture. Malgorzata s’est
          appropriée une technique qui consiste à employer une toile non préparée et une peinture très diluée qui
          traverse le support. Et, ce faisant, la nature qu’elle décrit est d’autant plus mouvante, changeante.
          La volonté est dorénavant de transcrire ce qui est le plus important : la lumière.
          Les œuvres récentes marquent une nouvelle étape. Non que l’artiste voudrait s’éloigner de ce qu’il faut bien
          considérer comme une certaine figuration, mais elle prend du recul à l’égard du sujet. Qu’il s’agisse de
          paysages éloignés ou de plans rapprochés, c’est de lumière dont il s’agit. Les ciels sont vaporeux, diaphanes,
          quelquefois menaçants. On évoque cette lumière du nord qu’affectionnent les peintres.
          Les paysages, zones d’ombre lourde, frondaisons, sous-bois accentuent les jeux d’ombre et de lumière. Et, bien
          entendu, tout ce qui a trait à l’eau et aux reflets. Lumière dédoublée lorsque le ciel se reflète dans
          l’étang, de sorte que cette lumière “mange” tout la surface de la toile.
          Site internet de l’artiste : <a href="https://paszko.net/">https://paszko.net/</a>

        </p>

        <!-- <p class="artists-content">Awards:</p>
        <p class="artists-content">1978: Prix de la Fondation de France (France)</p>
        <p class="artists-content">1981: Prix Salon de Montrouge, France (France)</p>
        <p class="artists-content">1986: Prix de Roma (Villa Médicis) (Italy)</p>
        <p class="artists-content">1987: Prix Lacourrière (Sculpture) (Paris)</p>
        <p class="artists-content">1992: Prix Martini (Paris)</p>
        <p class="artists-content">1999: Prix International Michelin (Paris)</p> -->

        <img :src="icons.artists_en_paszko1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_paszko2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group35')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_malgorzata_paszko_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_malgorzata_paszko_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Malgorzata PASZKO 马尔戈扎塔·帕斯科对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_ronel" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Christophe RONEL</span>

        <p class="artists-content">
          Christophe Ronel est né en 1964 à Rouen, il est initié très jeune à la peinture aux côtés d'un père peintre,
          il vit et travaille en Normandie et à Paris.
          Agrégé en arts plastiques, Ronel enseigne à l'Ecole Nationale Supérieure des Arts Appliqués et Métiers d'Arts
          Olivier de Serres à Paris à partir de 1990.
          Depuis une trentaine d'années, plus de quatre-vingt expositions personnelles lui ont été consacrées, en France
          et à l'étranger, aussi bien dans les galeries qu'en centres culturels et musées à Paris, Lille, Amiens, Le
          Touquet, La Baule, Rouen, Barbizon, Brest, Chartres, Grenoble, Lyon, Montpellier, Nice, Saint Jean De Luz,
          Saint Malo, Bruxelles, Casablanca, Marrakech, Sousse, Hanovre, Palm Beach, Singapour, Tokyo.
          Ses toiles ont notamment été exposées au château de Vascoeuil, à l'abbaye de Cercanceaux, au Palais
          Bénédictine de Fécamp, à L'Hôtel de Région de Rouen, aux Archives de Chartres, au Conseil Général de Seine
          Maritime, dans la collection Brittany Ferries et au musée de Nice - musée Bourdelle, Paris - musée de la
          Poste, Paris - musée des Beaux Arts, Le Havre - de Louviers, de Châteauroux, de Chartres, de Brive, d’Aix en
          Provence, de Gérone, de Sarajevo, de Shanghai, au centre d'art et de culture - Taegu, Corée - de Sarria -
          Espagne, dans les musées Japonais : Matsumoto, Fukuoka, Nagano, au Forum International de Tokyo, au Aoyama
          Spiral Hall de Tokyo, au Musée National de Chine à Pékin et au Musée National Chinois de Tianjin, au Musée
          National de Taiwan.
          Ronel a participé a de nombreux salons et biennales : Salon de Mai Grand Palais Paris, Grands et Jeunes Grand
          Palais Paris, Comparaisons Grand Palais Paris, Art en Capital Grand Palais Paris, Salon d'Automne, Groupe 109
          Grand Palais Paris, Art Paris, Saga, Linéart Gand, SIMAA Foire de Beyrouth, Biennale Internationale de Pékin,
          Artelys Bourg en Bresse, Start Foire de Strasbourg, Symposium international d'art sur papier de Taiwan etc...
          Ses oeuvres figurent dans diverses collections publiques et privées en France, Belgique, Pays- Bas, Allemagne,
          Autriche, Suisse, Italie, Maroc, Liban, Etats-Unis, Canada, Brésil, Australie, Singapour, Japon et Aux Emirats
          Arabes.
          Site internet de l’artiste : <a href="https://ronel.fr/">https://ronel.fr/</a>
        </p>
        <!-- <p class="artists-content">
          Currently, his works are collected by various public and private establishments in France, Belgium,
          Netherlands, Germany, Austria, Switzerland, Italy, Morocco, Lebanon, USA, Canada, Brazil, Australia,
          Singapore, Japan, and the United Arab Emirates.
        </p> -->

        <!-- <p class="artists-content">Awards:</p>

        <p class="artists-content">1985 : National Ocean Prize of France</p>
        <p class="artists-content">1993 : Gold Award of French Artists Salon</p>
        <p class="artists-content">1998 : First Prize of Paris Autumn Salon</p>
        <p class="artists-content">2002 : First Prize of Rouen Normandy Artist</p>
        <p class="artists-content">2004 : Rugale Michailov Award of Taylor Foundation</p> -->

        <img :src="icons.artists_en_ronel1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_ronel2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group67')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_christophe_ronel_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_christophe_ronel_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Christophe RONEL 克里斯托夫·罗内尔对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_renard" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Emmanuelle RENARD</span>

        <p class="artists-content">
          Née en 1963, Emmanuelle Renard vit et travaille à Paris.
          Peintre, dessinatrice, graveuse, céramiste, elle expose depuis 1986, en France et à
          l’étranger : Paris, Hambourg, Francfort, Karlsruhe, Londres, Bruxelles, Copenhague, Genève, Bâle, Stockholm,
          Chicago, Los Angeles, Beyrouth…
          Pour qualifier sa peinture, on pourrait parler d’expressionnisme surréaliste, ou l’inverse. Car c’est une
          peinture forte, puissante, très expressive, où les objets semblent prendre vie, où
          les personnages sont étranges, les scènes improbables et les couleurs franches. S’il fallait lui donner des
          ancêtres, on citerait volontiers les artistes baroques, et puis Goya, Soutine, Chagall, Rebeyrolle.
          Emmanuelle Renard est une artiste figurative majeure du panorama français actuel, qui a d’ailleurs séduit
          depuis trente ans beaucoup de collectionneurs, en France et à l’étranger.
        </p>
        <!-- <p class="artists-content">
          Her paintings can be defined as surreal expressionism, vice versa, for they are strong, powerful, and
          expressive. Her paintings feature vivid articles, eccentric figures, impossible scenarios, and pure colors. If
          it’s necessary to find an origin for such paintings, we will mention Baroque artists, Goya, Shamsuddin,
          Soutine, Chagall, and Rebeyrolle.
        </p> -->

        <img :src="icons.artists_en_renard1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_renard2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group89')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_emmanuelle_renard_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_emmanuelle_renard_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Emmanuelle RENARD 埃马纽埃尔·热娜徳对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_sainrapt" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Sophie SAINRAPT</span>

        <p class="artists-content">
          Ce qui interpelle lorsqu’on pénètre dans l’atelier de Sophie, ce n’est pas l’étalage de la nudité, c’est le
          respect dû à l’amour du corps. Ce corps jubilatoire qui exprime toute la reconnaissance de cette grande
          liberté que lui accorde l’artiste.
          Le corps livré, comme brut de tout décor, avec cette vérité crue qui jamais ne dérange. Le talent permet tout
          et la personnalité sans compromis de Sophie Sainrapt émeut.
          La démarche s’étend jusque dans le choix des supports et des médias utilisés. On comprend alors que ce parti
          pris n’a absolument rien de racoleur. Sophie exprime son plaisir à mouiller le papier, utilisant le grain
          comme une peau réactive à la substance liquide, jusqu’à toucher l’intangible pour mieux goûter l’amour et
          caresser la vie.
          Dans les postures scandaleusement normales qu’elle inflige à ses modèles, les couleurs coulent et s’amusent ;
          les rousses, les bistres, les bleus outremer, les noirs et blancs méditerranéens, les ors mats des
          japonaises…tout est sensualité, beauté, plaisir interlope, gisements chromatique, prisme de l’émotion,
          érotisme de l’œil. Elle pourrait être une contraction de Gustav Klimt et Egon Schiele décomplexés.
          Après des études de droits abouties Sophie Sainrapt entre au Sénat. Une carrière très sérieuse s’ouvre alors à
          la jeune femme, qui, parallèlement s’intéressera au dessin.
          Elle va suivre alors des cours privés sous la direction d’Hashpa. En 1995, elle fonde son atelier personnel à
          Paris et s’ensuit une période féconde qui va révéler toutes les facettes artistiques de la jeune plasticienne.
          Du dessin marouflé à la céramique, en passant par la technique de la gravure au linoléum, elle devient tout
          naturellement l’illustratrice de nombreux ouvrages de bibliophilie consacrés à Eros et ses jardins des
          délices.
          Les éditions Pasnic, Renard Pâle et Area, lui consacrent une place de choix dans leur florilège éditorial.Elle
          illustrera ainsi avec son talent débridé : Paul Verlaine, Jean de la Fontaine, Georges Bataille, Pierre Louÿs,
          Christian Noorbergen, Fernando Arrabal, René Vivien, ainsi que des ouvrages en duo avec Françoise Monnin et
          Pascal Aubier. Lettres à l’être et Secrets d’atelier.
          Cette blessure, chantée par Léo Ferré me vient en tête et je me souviens des paroles - que Sophie,
          inconsciemment illustre au quotidien - « Cette blessure, comme un parfum qui traîne à la marée, qui se referme
          au marbre du couteau, drapée de soie sous son triangle noir…cette blessure dont je viens…comme une couture sur
          le plaisir, comme une cicatrice de la nuit… ».
          Avec Sophie, l’effeuillage de la marguerite tombera forcément sur passionnément.
          Site internet de l’artiste : <a
            href="https://www.sophiesainrapt.com/index.html">https://www.sophiesainrapt.com/index.html</a>
        </p>

        <img :src="icons.artists_en_sainrapt1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_sainrapt2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group12')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_sophie_sainrapt_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_sophie_sainrapt_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Sophie SAINRAPT 苏菲·圣拉对“中国合作者说的一句话”</span> -->
      </div>

      <div class="artists-content-width column">
        <img :src="icons.artists_en_foldi" style="width:400px;align-self:center;margin-top:100px" />
        <span class="artists-label" style="margin-top: 20px">Augusto FOLDI</span>

        <p class="artists-content">
          Augusto Foldi,d'origine italo-hongroise, nait en 1955. Ecole des Beaux-Arts et des Arts Appliqués. Nourrit une
          double réflexion portant sur les Origines et la valeur réelle de l'engagement entretenues par l'Art - la
          Philosophie - la Rue.
          A la fin des années 60, vit dans le nord de la France. Réalise ses premières peintures. Voyage
          en Belgique, aux Pays-Bas.
          1975, s'installe à Paris, rue St-Paul. Entreprend une série de recherches sur les techniques à l'huile à
          travers le symbole et la métaphore.
          1977, parcourt l'Italie, l'Espagne, le Portugal. Naissance d' une thématique développée sur une interrogation
          mystique de l'Homme l'Art, la Création, s'appuyant sur une expérience d'ascendance orthodoxe dont il se sépare
          à partir de 1980, sacrifiant le détail à l'expression du caractère et du mouvement.
          1979, première exposition à Paris. Participe à différents Salons.
          1982, installe son atelier rue Villier de l'Isle-Adam. Entreprend la série des peintures
          "transfigures".
          1984, naissance des "gardiennes du fleuve", peintures. Organise des rencontres entre l'Inde, la France et le
          Sri-Lanka.
          1987, installe son atelier Porte de Chatillon à Malakoff. Débute la série des peintures "ex" en matière
          contrastée. Séjourne à Venise, à Rome.
          1990, séjourne en Toscane, prend pour modèle Carola, élabore un travail sur le processus du rituel dans la
          représentation du corps. Poursuit la série "ex" avec emploi brutal des tons.
          1992, création de "périphérie", papiers peints avec traces fragmentées du corps humain. Création de "portrex",
          photo-machine, "homoterrulentus". peintures.
          1993, avec les artistes Fabien Hommet et Herman Steins, création du groupe Localita : "Substituer à l'oeuvre
          la biographie, considérée à son tour comme objet d'art". Intervention durant la vente Cornette de
          St-Cyr/Malburet, Drouot-Richelieu, Paris. Actions Exhibitions dans les galeries et musées d'art moderne et
          contemporain des villes de : Paris, Bruxelles, Anvers, Amsterdam, Rotterdam Londres, Francfort, Cologne,
          Dusseldorf, Mönchengladbach, Krefeld, Paderborne, Berlin.
          1994, Localita, Atelier Mémoire : première vente publique d'une biographie d'artiste par M°Pierre Cornette de
          St-Cyr.
          1995, création d'une carte téléphonique "Points Cardinaux" éditée par l'A.F.E.J.I. "réussir ensemble".
          Présente "Fille", peinture manifeste au Salon de Montrouge.
          1996, Atelier d'écriture et d'expression graphique, en animation avec François Mathieu dans les classes
          primaires de Malakoff.
          1997, création de "suite", assiettes gravées, bitume. Installation Hôtel du Midi, Malakoff.
          1998, création de "passage", assiette gravée, bitume. Installation. Salon de Montrouge. Edition du journal
          "Fille", journal d'adresse. 8 pages, 350 exemplaires, impression offset. Avec la collaboration de Gilles
          Vaugeois, Olivier Pagès.
          1999, anime depuis 1998 un atelier de création avec les enfants de la crèche Avaulée de Malakoff.
          2000, exposition à la Galerie d'Art de Créteil, France. "Peinture en général-L'esprit des autres" peintures et
          bois polychromes.
          2002, expose en permanence sur le site face-art-paris.com
          2002, exposition au Centre national de l'estampe et de l'art imprimé : CNEAI Chatou, France.
          2002, conférence Ateliers Exposition, banlieue : Mythes et Réalités. Département of Language and Cultural
          Studies de l'université de Lilmerick, Irlande. Avec la participation de la Limerick Scool of Art and Design et
          le soutien de l'Ambassade de France.
          2003, exposition à l'Orangerie du Sénat. "animal et territoire". Peinture polyptyque, Artsénat,Paris
          2004, exposition au Centre d'Art de la Tour, Tardais, France.
          2004, festival d'Art Contemporain de Bar le Duc, France. Réalisation d'un cour métrage en résidence avec le
          soutient du Conseil Général de la Meuse, de la Direction Régionale des Affaires Culturelles de Lorraine.
          2005, exposition château de Sainte-Suzanne, France. Avec le soutient du Conseil Général de la Mayenne.
          2006, exposition Galerie Suty, Coye la Forêt, France. La saison du blanc. Bois polychromes.
          www.galeriesuty.com
          2006-2008, Art Sénat. Paris. Les Jardins du Luxembourg. "taille humaine". Bois polychromes.
          2008, Galerie Sellem. Paris. Exposition "Spring!". Peintures, dessins.
          2008, Manoir de Couesme. Musée de l'Épouvantail. France. Exposition, sculptures en bois polychrome. Collection
          permanente du Musée.
          2008, Galerie Univer. Paris. Exposition. Peintures, céramiques.
          2008, espace d'Art contemporain Eugène Beaudoin, Paris.
          2009, Galerie Alice Mogabgab. Beyrouth - Liban. "virtuellement réel". Peintures.
          2012, Espace d'Art Contemporain Eugène Beaudouin. Antony. France. Alors 2012 : Signalétique de l’exposition.
          Bois polychrome.
          Réside et travaille à Malakoff.
          Site internet de l’artiste : <a href="http://www.atelierfoldi.com/">http://www.atelierfoldi.com/</a>

        </p>

        <img :src="icons.artists_en_foldi1" style="margin-top: 20px;width:400px;align-self:center" />
        <img :src="icons.artists_en_foldi2" style="margin-top: 20px;width:400px;align-self:center" />

        <!-- <span class="artists-link" style="align-self: flex-end" @click="navigateTo('group14')">点此查看艺术家观点</span> -->

        <!-- <img class="artists-content-width" style="margin-top: 20px" :src="icons.artists_augusto_foldi_image" /> -->

        <!-- <video style="width: 800px; margin-top: 20px" :src="icons.artists_augusto_foldi_video" controls></video> -->

        <!-- <span class="artists-desc" style="align-self: center">Augusto FOLDI 奥古斯都·弗乐迪对“中国合作者说的一句话”</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  methods: {
    navigateTo(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style scoped>
.artists-banner {
  position: relative;
  display: block;
}
.artists-huxian {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.artists-title {
  font-size: 22px;
  color: #282828;
  letter-spacing: 0;
}
.artists-label {
  font-weight: bold;
  font-size: 18px;
  color: #282828;
  letter-spacing: 0;
}
.artists-content {
  font-size: 16px;
  color: #282828;
  letter-spacing: 0;
  text-indent: 25px;
}
.artists-link {
  cursor: pointer;
  font-size: 16px;
  color: #282828;
  font-weight: bold;
  letter-spacing: 0;
  color: #154a80;
  text-decoration: underline;
}
.artists-desc {
  margin-top: 10px;
  font-size: 16px;
  color: #282828;
  font-weight: bold;
  letter-spacing: 0;
  color: #154a80;
}
.artists-content-width {
  width: 800px;
}
</style>